<template src='./dashboard.html'>    
</template>
<style scoped src='./dashboard.css'>
</style>
<script>
import Loading from 'vue-loading-overlay'
import chart from '@/components/chart/chart'
import TransactionService from '@/services/TransactionService.vue'
import HomeService from '@/services/HomeService.vue'
import moment from 'moment'
import transactionConstants from '@/constants/TransactionConstants.vue'
import AccountConstants from '@/constants/AccountConstants.vue'
import Utils from '@/utils/utils'
import { mapActions, mapGetters } from 'vuex'
import pagination from '@/components/vtable/vtpagination.vue'
import AppConstants from '../../constants/AppConstants.vue'

function transaction_status_mapping(info) {
  for (let x = 0; x < info.length; ++x) {
    info[x].TransactionStatus = transactionConstants.transactionStatus.find(s => s.Value === info[x].TransactionStatus).Text
  }
}

function account_status_mapping(info) {
  for (let x = 0; x < info.length; ++x) {
    info[x].AccountStatus = AccountConstants.accountStatus.find(s => s.Value === info[x].AccountStatus).Text
  }
}

function sign_up_device_mapping(info) {
  for (let x = 0; x < info.length; ++x) {
    if (!(info[x].SignUpDeviceOrigin)) {
      info[x].SignUpDeviceOrigin = 'Unknown'
    }
    else {
      info[x].SignUpDeviceOrigin = AccountConstants.deviceOrigin.find(s => s.Value === info[x].SignUpDeviceOrigin).Text
    }
  }
}

function transaction_status_chart_helper(info) {
  let pending = 0;
  let waitingforfunding = 0;
  let complete = 0;
  let sendercompliancepending = 0;
  let failed = 0;
  let beneficiarynotallowedforremit = 0;
  for (let x = 0; x < info.length; ++x) {
    // if (info[x].RecipientName === null) {
    //   info[x].RecipientName = " "
    // }
    const origin = info[x].TransactionStatus
    if (origin === "Pending") {
      pending++;
    } else if (origin === "Waiting for Funding") {
      waitingforfunding++;
    } else if (origin === "Completed") {
      complete++;
    } else if (origin === "Sender Compliance Pending") {
      sendercompliancepending++;
    }
    else if (origin === "Failed") {
      failed++;
    }
    else if (origin === 'Beneficiary not Allowed for Remit') {
      beneficiarynotallowedforremit++;
    }
  }
  return [pending, waitingforfunding, failed, sendercompliancepending, complete, beneficiarynotallowedforremit]
}

function tranfer_type_chart_helper(info) {
  let EFT = 0;
  let MoneyTransfer = 0;
  let BankAccount = 0;
  let PromoCode = 0;
  let ETransfer = 0;
  for (let x = 0; x < info.length; ++x) {
    const origin = info[x].TransactionType
    if (origin === "EFT") {
      EFT++;
    } else if (origin === "Money Transfer") {
      MoneyTransfer++;
    } else if (origin === "From Bank Account") {
      BankAccount++;
    } else if (origin === "Promo Code Redeeming") {
      PromoCode++;
    }
    else if (origin === "E-Transfer") {
      ETransfer++;
    }
  }
  return [EFT, ETransfer, MoneyTransfer, BankAccount, PromoCode]
}

function account_status_chart_helper(info) {
  let enabled = 0;
  let disabled = 0;
  let closed = 0;
  let signedup = 0;
  for (let x = 0; x < info.length; ++x) {
    const origin = info[x].AccountStatus
    if (origin === 'Enabled') {
      enabled++;
    } else if (origin === 'Disabled') {
      disabled++;
    } else if (origin === 'Closed') {
      closed++;
    } else if (origin === 'SignedUp') {
      signedup++;
    }
  }
  return  [ enabled, disabled, closed, signedup ]
}

function device_chart_helper(info) {
  let ios = 0;
  let android = 0;
  let  webclient = 0;
  let unknowndevice = 0;
  for (let x = 0; x < info.length; ++x) {
    const origin = info[x].SignUpDeviceOrigin
    if (origin === "ios") {
      ios++;
    } else if (origin === "Android") {
      android++;
    } else if (origin === "WebClient") {
      webclient++;
    } else if (origin === 'Unknown') {
      unknowndevice++;
    }
  }
  return [ios, android, webclient, unknowndevice]
}

export default {
  components: {
    Loading, chart, pagination
  },
  data () {
    return {
      transactionSearchField: "",
      transactionSearchValue: "",
      searchTransactionType: "",
      searchTransactionStatus: "",
      transactionDateFrom: "",
      transactionDateTo: "",
      transactionDateFilter: 'lastDay',
      transactionTableData: [],
      usersTableData: [],
      usersSearchField: "",
      usersSearchValue: "",
      searchSignUpDevice: "",
      searchAccountStatus: "",
      userDateTo: "",
      userDateFrom: "",
      sourceAmountFrom: "",
      sourceAmountTo: "",
      columns1: ['DateTime', 'ParentReferenceNumber', 'ReferenceNumber', 'SenderEmail', 'RecipientName', 'SourceCurrency', 'Amount', 'TransactionStatus', 'TransactionType', 'ClientIP', 'Action'],
      chartOptions1: {
        responsive: true,
        maintainAspectRatio: false
      },
      options1: {
        headings: {
          OrigDateTime : 'Date and Time',
          ParentReferenceNumber: 'Parent Reference Number',
          ReferenceNumber : 'Reference Number',
          SenderName : 'Sender Name',
          SenderEmail: 'Sender Email',
          RecipientName: 'Recipient Name', 
          SourceCurrency: 'Source Currency', 
          Amount: 'Source Amount', 
          TargetCurrency: 'Target Currency', 
          TargetAmount: 'Target Amount', 
          TransactionStatus: 'Transaction Status', 
          TransactionType: 'Transaction Type',
          ClientIP: 'ClientIP'
        },
        uniqueKey : 'ReferenceNumber'
      },
      columns2: ['OrigSignUpDate', 'AccountStatus', 'Email', 'FirstName', 'LastName', 'SignUpDeviceOrigin', 'SignUpDeviceIP', 'Action'],
      chartOptions2: {
        responsive: true,
        maintainAspectRatio: false
      },
      options2: {
        headings: {
          AccountStatus : 'Account Status',
          Email : 'Email',
          FirstName : 'First Name',
          LastName: 'Last Name',
          OrigSignUpDate: 'Sign Up Date', 
          SignUpDeviceOrigin: 'Sign Up Device',
          SignUpDeviceIP: 'DeviceIP' 
        }
      },
      utils : Utils,
      loaderVisible: false,
      accountStatus: '0',
      signUpDevice: "",
      accountStatusList: AccountConstants.accountStatus,
      deviceOriginList: AccountConstants.deviceOrigin,
      widgetOptions:{
          headings: {
              type: 'Type',
              count: 'Count',
              action: 'Action'
          },
          filterable: false,
          pagination:{
            show: false
          }
      },
      widgetColumns: ['type','count','action'],
      widgetData: [],
      isLazyLoadingEnabled: AppConstants.enableLazyLoading
    }
  },
  computed: {
    ...mapGetters(['getNonZeroAccountuser', 'getReviewRequiredUser', 'getSignedUser']),
    transactiondatafiltered() { 
      let data = JSON.parse(JSON.stringify(this.transactionTableData))
      const field = this.transactionSearchField;
      const value = this.transactionSearchValue.toLowerCase();
      if (value !== "" && field !== "") {
        data = data.filter(item => (""+item[field]).toLowerCase().startsWith(value));
      }
      if (this.searchTransactionStatus !== "") {
        data = data.filter(item => item.TransactionStatus._text === this.searchTransactionStatus)
      }
      if (this.searchTransactionType !== "") {
        data = data.filter(item => item.TransactionType._text === this.searchTransactionType)
      }
      if (this.transactionDateFrom !== "") {
        data = data.filter(item => +new Date(item.DateTime._text) >= new Date(this.transactionDateFrom))
      }
      if (this.transactionDateTo !== "") {
        data = data.filter(item => +new Date(item.DateTime._text) <= new Date(this.transactionDateTo))
      }
      if (this.sourceAmountFrom !== "") {
        data = data.filter(item => parseFloat(item.Amount._text).toFixed(2) >= this.sourceAmountFrom)
      }
        if (this.sourceAmountTo !== "") {
        data = data.filter(item => parseFloat(item.Amount._text).toFixed(2) <= this.sourceAmountTo)
      }
      if(this.isLazyLoadingEnabled) {
        const updatePaginationTotalRecords = () => {
          if(this.$refs.paginationRefTransaction) {
            this.$refs.paginationRefTransaction.selectedPage = 1
          }
        }
        updatePaginationTotalRecords()
      }
      return data 
    },
    paginateTransactionData() {
      let transactionData = []
      if(this.transactiondatafiltered && this.transactiondatafiltered.length > 0) {
        transactionData = JSON.parse(JSON.stringify(this.transactiondatafiltered))
        if(this.isLazyLoadingEnabled) {
          let selectedPage = this.$refs.paginationRefTransaction.selectedPage
          transactionData = transactionData.slice((selectedPage - 1)* 10, selectedPage * 10)
        }
        transactionData.forEach(transaction => {
          Object.keys(transaction).map(key => {
            transaction[key] = transaction[key]._text ? transaction[key]._text : null
          })
        })
        transaction_status_mapping(transactionData)
      }
      const updateTotalRecords = () => {
        if(this.$refs.paginationRefTransaction) {
          this.$refs.paginationRefTransaction.totalRecords = this.transactiondatafiltered?.length ?? 0
        }
      }
      this.isLazyLoadingEnabled ? updateTotalRecords() : null
      return transactionData
    },
    // usersdatafiltered() {
    //   let data = this.usersTableData
    //   const field = this.usersSearchField;
    //   const value = this.usersSearchValue.toLowerCase();
    //   if (value !== "" && field !== "") {
    //     data = data.filter(item => (""+item[field]).toLowerCase().startsWith(value))
    //   }
    //   if (this.searchSignUpDevice !== "") {
    //     data = data.filter(item => item.SignUpDeviceOrigin === this.searchSignUpDevice)
    //   }
    //   if (this.searchAccountStatus !== "") {
    //     data = data.filter(item => item.AccountStatus === this.searchAccountStatus)
    //   }
    //   if (this.userDateFrom !== "") {
    //     data = data.filter(item => item.SignUpDate >= new Date(this.userDateFrom))
    //   }
    //   if (this.userDateTo !== "") {
    //     data = data.filter(item => item.SignUpDate <= new Date(this.userDateTo))
    //   }
    //   return data
    // },
    paginateUserSummaryData() {
      let usersSummary = []
      if(this.usersTableData && this.usersTableData.length > 0) {
        usersSummary = JSON.parse(JSON.stringify(this.usersTableData))
        if(this.isLazyLoadingEnabled) {
          let selectedPage = this.$refs.paginationRefUsersSummary.selectedPage
          usersSummary = usersSummary.slice((selectedPage - 1) * 10, selectedPage * 10)
        }
        usersSummary = this.removeUnderScoreText(usersSummary)
        account_status_mapping(usersSummary)
        sign_up_device_mapping(usersSummary)
        usersSummary.forEach(item => {
          const date = item.SignUpDate ? moment(item.SignUpDate, 'MMM DD YYYY HH:mm:ss A') : undefined
          item['SignUpDate'] = date ? +date : '',
          item['OrigSignUpDate'] = date ?  date.format('MM/DD/YYYY HH:mm:ss') : ''
        })
      }
      const updatePaginationTotalRecords = () => {
        if(this.$refs.paginationRefUsersSummary) {
          this.$refs.paginationRefUsersSummary.totalRecords = this.usersTableData?.length ?? 0
        }
      }
      if(this.isLazyLoadingEnabled) 
        updatePaginationTotalRecords()
      return usersSummary
    },
    usersChartData1() {
      let userslist1 = []
      if(this.usersTableData) {
        let data = JSON.parse(JSON.stringify(this.usersTableData))
        data = this.removeUnderScoreText(data)
        sign_up_device_mapping(data)
        userslist1 = device_chart_helper(data)
      }
      return {
        labels: ['iOS', 'Android','Web Client','Unknown'],
        datasets: [
          {
            label: 'Device',
            backgroundColor: '#f87979',
            data : userslist1
          },
        ]
      }
    },
    usersChartData2() {
      let userslist2 = []
      if(this.usersTableData) {
        let data = JSON.parse(JSON.stringify(this.usersTableData))
        data = this.removeUnderScoreText(data)
        account_status_mapping(data)
        userslist2 = account_status_chart_helper(data)
      }
      return {
        labels: ['Enabled', 'Disabled','Closed','Signed Up'],
        datasets: [
          {
            label: 'Account Status',
            backgroundColor: '#f87979',
            data : userslist2
          },
        ]
      }
    },
    transactionsChartData2() {
      let transactionlist2 = []
      if(this.transactiondatafiltered && this.transactiondatafiltered.length > 0) {
        let data = JSON.parse(JSON.stringify(this.transactiondatafiltered))
        data = this.removeUnderScoreText(data)
        transactionlist2 = tranfer_type_chart_helper(data) 
      }
      return {
        labels: ['EFT', 'E-Transfer', 'Money Transfer','From Bank Account','Promo Code Redeeming'],
        datasets: [
          {
            label: 'Transactions',
            backgroundColor: '#f87934',
            data: transactionlist2
          }
        ]
      }
    },
    transactionsChartData1() {
      let transactionlist1 = []
      if(this.transactiondatafiltered && this.transactiondatafiltered.length > 0) {
        let data = JSON.parse(JSON.stringify(this.transactiondatafiltered))
        data = this.removeUnderScoreText(data)
        transaction_status_mapping(data)
        transactionlist1 = transaction_status_chart_helper(data)
      }
      return {
        labels: ['Pending', 'Waiting for Funding', 'Failed','Sender Compliance Pending','Complete', 'Beneficiary Not Allowed for Remit'],
        datasets: [
          {
            label: 'Transactions',
            backgroundColor: '#f87934',
            data: transactionlist1
          }
        ]
      }
    },
    transactionsChartDataKey1() { return this.transactionsChartData1 ? Utils.methods.uuidv4() : Utils.methods.uuidv4()},
    transactionsChartDataKey2() { return this.transactionsChartData2 ? Utils.methods.uuidv4() : Utils.methods.uuidv4()},
    usersChartDataKey1() { return this.usersChartData1 ? Utils.methods.uuidv4() : Utils.methods.uuidv4()},
    usersChartDataKey2() { return this.usersChartData2 ? Utils.methods.uuidv4() : Utils.methods.uuidv4()},
    today () { return new Date() },
  },
  methods : {
    ...mapActions(['fetchNonZeroAccountUser', 'fetchReviewRequiredUser', 'fetchSignedUser']),
    accountAction(row,action){
      this.$router.push({
        name  : action,
        params: {
          email : row.Email,
          fromName: this.$route.name,
          dashboardUserSummaryFilters: {
            accountStatus: this.accountStatus,
            signUpDevice: this.signUpDevice
          }
        }
      })
    },
    printTransaction(row) {
      try {
        this.$router.push({
          name: 'PrintTransaction',
          params : {
            TransactionId : row.TransactionId,
            ParentReferenceNumber : row.ParentReferenceNumber,
            CustomerEmail : row.SenderEmail,
            TransactionStatus : row.TransactionStatus,
            fromName : this.$route.name,
            dashboardTransactionFilters: {
              transactionSearchField: this.transactionSearchField,
              transactionSearchValue: this.transactionSearchValue,
              searchTransactionStatus: this.searchTransactionStatus,
              searchTransactionType: this.searchTransactionType,
              sourceAmountFrom: this.sourceAmountFrom,
              sourceAmountTo: this.sourceAmountTo,
              transactionDateFrom: this.transactionDateFrom,
              transactionDateTo: this.transactionDateTo,
              transactionDateFilter: this.transactionDateFilter
            }
          }
        })
      } catch (error) {
        //this.getToaster('danger','Qicsend Says',error)
      }
    },
    async getTransactionSummaryData() {
      try {
        this.loaderVisible = true
        let _date = this.transactionDateFilter === 'lastDay' ? new Date(Date.now() - (24*3600*1000)) : (this.transactionDateFilter === 'lastWeek' ? new Date(Date.now() - (7*24*3600*1000)) : null )
        let fromDate, toDate
        if (_date) {
          fromDate = this.utils.methods.LocalToUTC(moment(_date).format('MM-DD-YYYY 00:00:00'))
          toDate = this.utils.methods.LocalToUTC(moment(new Date()).format('MM-DD-YYYY HH:mm:ss'))
        }
        let TransactionTableData = await TransactionService.methods.GetTransactionSummary({
          TransactionDate: fromDate,
          TransactionDateTo: toDate
        })
        TransactionTableData = TransactionTableData.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
        if(TransactionTableData) {
          this.transactionTableData = Array.isArray(TransactionTableData) ? TransactionTableData : [TransactionTableData]
        }else{
          this.transactionTableData = []
        }
          // .map(obj => {
          //   let dict = {}
          //   for (let key in obj) {
          //     dict[key] = obj[key]._text
          //   }
          //   return dict
          // })
          // .map(row => {
          //   return {
          //     ...row,
          //     DateTime: +(new Date(row.DateTime)),
          //     Amount: parseFloat(+row.Amount).toFixed(2),
          //     OrigDateTime: row.DateTime,
          //     TargetAmount: parseFloat(+row.TargetAmount).toFixed(2),
          //   }
          // })
          // transaction_status_mapping(this.transactionTableData)
        // } else {
        //   this.transactionTableData = []
        // }
        this.loaderVisible = false 
      } catch (error) {
        this.loaderVisible = false
      }
    },
    async getUserSummaryData() {
      try {
        this.accountStatus = this.accountStatus === 'null' ? null : this.accountStatus
        this.loaderVisible = true
        let UsersTableData = (await HomeService.methods.GetUserSummary(
          {"AccountBalanceGreaterThan":"-1",
           "AccountStatus": this.accountStatus,
           "SignUpDeviceOrigin": this.signUpDevice
          }
          )).csGetUsersSummaryResponse.UsersSummaryData.UserSummaryData
        if(UsersTableData) {
          this.usersTableData = Array.isArray(UsersTableData) ? UsersTableData : [UsersTableData] 
        }else{
          this.usersTableData = []
        }
        if(this.isLazyLoadingEnabled) {
          this.$refs.paginationRefUsersSummary.selectedPage = 1
        }
          // .map(obj => {
          //   let dict = {}
          //   for (let key in obj) {
          //     dict[key] = obj[key]._text
          //   }
          //   return dict
          // })
          // .map(row => {
          //   const date = row.SignUpDate ? moment(row.SignUpDate, 'MMM DD YYYY HH:mm:ss A') : undefined
          //   return {
          //     ...row,
          //     SignUpDate: date ? +date : '',
          //     OrigSignUpDate:date ?  date.format('MM/DD/YYYY HH:mm:ss') : '',
          //   }
          // })
          // account_status_mapping(this.usersTableData)
          // sign_up_device_mapping(this.usersTableData)
        this.loaderVisible = false
      } catch (error) {
        this.loaderVisible = false
      }
    },
    async loadDashboardWidget() {
      this.loaderVisible = true
      await Promise.all([this.fetchNonZeroAccountUser(),  this.fetchReviewRequiredUser(), this.fetchSignedUser()])
      this.widgetData  = [
        {
            type  : 'Accounts - Non-zero balance', 
            count : await this.getNonZeroAccountuser?.UsersSummaryData?.UserSummaryData?.length,
            name  : 'NZB'
        },
        {
            type  : 'Accounts - Review Required', 
            count : await this.getReviewRequiredUser?.length,
            name  : 'RR'
        },
        {
            type  : 'Accounts - Signed Up', 
            count : await this.getSignedUser?.UsersSummaryData?.UserSummaryData?.length,
            name  : 'SU'
        }
      ]
      this.loaderVisible = false
    },
    redirectionEvent(props) {
      this.$router.push({
        name: 'UserSummary',
        params: {
          from: 'dashboardWidget',
          type: props.name
        }
      })
    },
    removeUnderScoreText(data) {
      data.forEach(transaction=> {
        Object.keys(transaction).map(key => transaction[key] = transaction[key]._text ? transaction[key]._text : null)
      })
      return data
    }
  },
  async mounted () {
    try {
      this.loaderVisible = true
      let filters = this.$route.params.filters?.dashboardTransactionFilters || null
      if(filters) {
        this.transactionSearchField = filters.transactionSearchField || ""
        this.transactionSearchValue = filters.transactionSearchValue || ""
        this.searchTransactionStatus = filters.searchTransactionStatus || ""
        this.searchTransactionType = filters.searchTransactionType || ""
        this.sourceAmountFrom = filters.sourceAmountFrom || ""
        this.sourceAmountTo = filters.sourceAmountTo || ""
        this.transactionDateFrom = filters.transactionDateFrom || ""
        this.transactionDateTo = filters.transactionDateTo || ""
        this.transactionDateFilter = filters.transactionDateFilter || "lastDay"
      }
      let UFilters = this.$route.params.dashboardUserSummaryFilters || null
      if(UFilters) {
        this.accountStatus = UFilters.accountStatus || '0'
        this.signUpDevice = UFilters.signUpDevice || ''
      }
      await this.getTransactionSummaryData()
      await this.getUserSummaryData()
      await this.loadDashboardWidget() // data for Dashboard widget
      this.loaderVisible = false
    } catch (error) {
      this.loaderVisible = false
    }
  },
}
</script>
